<template>
    <div>
        <Breadcrumbs :type="type" :header="title" :url="url" :head="head"></Breadcrumbs>
        <trashed-message v-if="citizens.deleted_at" class="mb-6"> This Citizen has been deleted. </trashed-message>
        <nav class="bg-gray-100 px-5 py-3 rounded-md w-full">
            <strong>Profile Verification</strong>
        </nav>
        <div class="grid md:grid-cols-2 gap-3">
            <!-- Right Side -->
            <div class=" ">
                <div class="bg-white md:p-3 p-1 shadow-sm rounded-sm">
                    <div class="flex items-center space-x-2 font-semibold text-gray-900 leading-8">
                        <span clas="text-green-500">
                            <svg class="h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                            </svg>
                        </span>
                        <span class="tracking-wide">Citizen Information</span>
                        <span>
                            <span v-if="citizens.govt_issue_id_status == 0" class=""><i
                                    class="fa fa-check-circle"></i></span>
                            <span v-if="citizens.govt_issue_id_status == 1" class="success"><i
                                    class="fa fa-check-circle"></i></span>

                            <span v-if="citizens.govt_issue_id_status == 2" class="fail"><i
                                    class="fa fa-times-circle"></i></span>
                            <span v-if="citizens.govt_issue_id_status == 5" class="fail"><i
                                    class="fa fa-lock"></i></span>
                        </span>
                    </div>
                    <div class="text-gray-700">
                        <div class=" text-sm">
                            <div class="grid grid-cols-7">
                                <div class="md:px-4 py-2 font-semibold col-span-3">First Name</div>
                                <div class="md:px-4 py-2 font-semibold">:</div>
                                <div class="md:px-4 py-2 col-span-3">{{ citizens.first_name ? citizens.first_name : null
                                    }}
                                </div>
                            </div>
                            <div class="grid grid-cols-7">
                                <div class="md:px-4 py-2 font-semibold col-span-3">Middle Name</div>
                                <div class="md:px-4 py-2 font-semibold">:</div>
                                <div class="md:px-4 py-2 col-span-3">{{ citizens.middle_name ? citizens.middle_name :
            null
                                    }}
                                </div>
                            </div>
                            <div class="grid grid-cols-7">
                                <div class="md:px-4 py-2 font-semibold col-span-3">Last Name</div>
                                <div class="md:px-4 py-2 font-semibold">:</div>
                                <div class="md:px-4 py-2 col-span-3">{{ citizens.last_name ? citizens.last_name : null
                                    }}
                                </div>
                            </div>
                            <div class="grid grid-cols-7">
                                <div class="md:px-4 py-2 font-semibold col-span-3">Suffix</div>
                                <div class="md:px-4 py-2 font-semibold">:</div>
                                <div class="md:px-4 py-2 col-span-3">{{ citizens.surfix ? citizens.surfix : null }}
                                </div>
                            </div>
                            <div class="grid grid-cols-7">
                                <div class="md:px-4 py-2 col-span-3 font-semibold">Mobile</div>
                                <div class="md:px-4 py-2 font-semibold">:</div>
                                <div class="md:px-4 py-2 col-span-3">
                                    {{ citizens.loginfo.mobile ? citizens.loginfo.mobile : null }}</div>
                            </div>
                            <div class="grid grid-cols-7">
                                <div class="md:px-4 py-2 col-span-3 font-semibold">Email</div>
                                <div class="md:px-4 py-2 font-semibold">:</div>
                                <div class="md:px-4 py-2 col-span-3">{{ citizens.loginfo.email ? citizens.loginfo.email
            :
            null }}
                                </div>
                            </div>
                            <div class="grid grid-cols-7">
                                <div class="md:px-4 py-2 col-span-3 font-semibold">Username</div>
                                <div class="md:px-4 py-2 font-semibold">:</div>
                                <div class="md:px-4 py-2 col-span-3">
                                    {{ citizens.loginfo.username ? citizens.loginfo.username : null }}</div>
                            </div>
                            <div class="grid grid-cols-7">
                                <div class="md:px-4 py-2 col-span-3 font-semibold">Gender</div>
                                <div class="md:px-4 py-2 font-semibold">:</div>
                                <div class="md:px-4 py-2 col-span-3">
                                    {{ citizens.gender == 1 ? 'Male' : '' }}{{ citizens.gender == 2 ? 'Female' : '' }}
                                </div>
                            </div>
                            <div class="grid grid-cols-7">
                                <div class="md:px-4 py-2 col-span-3 font-semibold">Govt. Issued ID Type</div>
                                <div class="md:px-4 py-2 font-semibold">:</div>
                                <div class="md:px-4 py-2 col-span-3 wordWrap"
                                    v-if="citizens.government_issued_id_type != null">
                                    {{ citizens.government_issued_id_type.name }}</div>
                            </div>
                            <div class="grid grid-cols-7">
                                <div class="md:px-4 py-2 col-span-3 font-semibold">Govt. Issued ID</div>
                                <div class="md:px-4 py-2 font-semibold">:</div>
                                <div class="md:px-4 py-2 col-span-3">{{
            citizens.govt_issue_id_number ? citizens.govt_issue_id_number : null }}</div>
                            </div>
                            <div class="grid grid-cols-7">
                                <div class="md:px-4 py-2 col-span-3 font-semibold">Date of Birth</div>
                                <div class="md:px-4 py-2 font-semibold">:</div>
                                <div class="md:px-4 py-2 col-span-3">{{ citizens.dob }}</div>
                            </div>

                            <hr>
                            <p class="md:px-4 py-2 col-span-3 font-semibold">Previous Data</p>
                            <hr>
                            <div class="grid grid-cols-7" v-if="citizens.govt_employee_status == 1">
                                <div class="md:px-4 py-2 col-span-3 font-semibold">Office Layer</div>
                                <div class="md:px-4 py-2 font-semibold">:</div>
                                <div class="md:px-4 py-2 col-span-3">{{ geodata.office_layer.title }}</div>
                            </div>
                            <div class="grid grid-cols-7" v-if="citizens.govt_employee_status == 1">
                                <div class="md:px-4 py-2 col-span-3 font-semibold">Office</div>
                                <div class="md:px-4 py-2 font-semibold">:</div>
                                <div class="md:px-4 py-2 col-span-3">{{ geodata.office.title }}</div>
                            </div>
                            <div class="grid grid-cols-7"
                                v-if="citizens.govt_employee_status == 1 && geodata.ministry.title != null">
                                <div class="md:px-4 py-2 col-span-3 font-semibold">Ministry</div>
                                <div class="md:px-4 py-2 font-semibold">:</div>
                                <div class="md:px-4 py-2 col-span-3">{{ geodata.ministry.title }}</div>
                            </div>
                            <div class="grid grid-cols-7"
                                v-if="citizens.govt_employee_status == 1 && geodata.region.title != null">
                                <div class="md:px-4 py-2 col-span-3 font-semibold">Region</div>
                                <div class="md:px-4 py-2 font-semibold">:</div>
                                <div class="md:px-4 py-2 col-span-3">{{ geodata.region.title }}</div>
                            </div>
                            <div class="grid grid-cols-7"
                                v-if="citizens.govt_employee_status == 1 && geodata.province.title != null">
                                <div class="md:px-4 py-2 col-span-3 font-semibold">Province</div>
                                <div class="md:px-4 py-2 font-semibold">:</div>
                                <div class="md:px-4 py-2 col-span-3">{{ geodata.province.title }}</div>
                            </div>
                            <div class="grid grid-cols-7"
                                v-if="citizens.govt_employee_status == 1 && geodata.municipality.title != null">
                                <div class="md:px-4 py-2 col-span-3 font-semibold">Municipality</div>
                                <div class="md:px-4 py-2 font-semibold">:</div>
                                <div class="md:px-4 py-2 col-span-3">{{ geodata.municipality.title }}</div>
                            </div>
                            <div class="grid grid-cols-7"
                                v-if="citizens.govt_employee_status == 1 && geodata.barangay.title != null">
                                <div class="md:px-4 py-2 col-span-3 font-semibold">Barangay</div>
                                <div class="md:px-4 py-2 font-semibold">:</div>
                                <div class="md:px-4 py-2 col-span-3">{{ geodata.barangay.title }}</div>
                            </div>
                            <div class="grid grid-cols-7" v-if="citizens.govt_employee_status == 1">
                                <div class="md:px-4 py-2 col-span-3 font-semibold">Designation</div>
                                <div class="md:px-4 py-2 font-semibold">:</div>
                                <div class="md:px-4 py-2 col-span-3">{{ geodata.designation.title }}</div>
                            </div>
                            <div class="grid grid-cols-7"
                                v-if="citizens.govt_employee_status == 1 && geodata.joining_date != null">
                                <div class="md:px-4 py-2 col-span-3 font-semibold">Joining Date</div>
                                <div class="md:px-4 py-2 font-semibold">:</div>
                                <div class="md:px-4 py-2 col-span-3">{{ geodata.joining_date }}</div>
                            </div>

                            <hr>
                            <p class="md:px-4 py-2 col-span-3 font-semibold">Requested Data</p>
                            <hr>
                            <div class="grid grid-cols-7" v-if="citizens.govt_employee_status == 1">
                                <div class="md:px-4 py-2 col-span-3 font-semibold">Office Layer</div>
                                <div class="md:px-4 py-2 font-semibold">:</div>
                                <div class="md:px-4 py-2 col-span-3">{{ newGeodata.office_layer.title }}</div>
                            </div>
                            <div class="grid grid-cols-7" v-if="citizens.govt_employee_status == 1">
                                <div class="md:px-4 py-2 col-span-3 font-semibold">Office</div>
                                <div class="md:px-4 py-2 font-semibold">:</div>
                                <div class="md:px-4 py-2 col-span-3">{{ newGeodata.office.title }}</div>
                            </div>
                            <div class="grid grid-cols-7"
                                v-if="citizens.govt_employee_status == 1 && newGeodata.ministry.title != null">
                                <div class="md:px-4 py-2 col-span-3 font-semibold">Ministry</div>
                                <div class="md:px-4 py-2 font-semibold">:</div>
                                <div class="md:px-4 py-2 col-span-3">{{ newGeodata.ministry.title }}</div>
                            </div>
                            <div class="grid grid-cols-7"
                                v-if="citizens.govt_employee_status == 1 && newGeodata.region.title != null">
                                <div class="md:px-4 py-2 col-span-3 font-semibold">Region</div>
                                <div class="md:px-4 py-2 font-semibold">:</div>
                                <div class="md:px-4 py-2 col-span-3">{{ newGeodata.region.title }}</div>
                            </div>
                            <div class="grid grid-cols-7"
                                v-if="citizens.govt_employee_status == 1 && newGeodata.province.title != null">
                                <div class="md:px-4 py-2 col-span-3 font-semibold">Province</div>
                                <div class="md:px-4 py-2 font-semibold">:</div>
                                <div class="md:px-4 py-2 col-span-3">{{ newGeodata.province.title }}</div>
                            </div>
                            <div class="grid grid-cols-7"
                                v-if="citizens.govt_employee_status == 1 && newGeodata.municipality.title != null">
                                <div class="md:px-4 py-2 col-span-3 font-semibold">Municipality</div>
                                <div class="md:px-4 py-2 font-semibold">:</div>
                                <div class="md:px-4 py-2 col-span-3">{{ newGeodata.municipality.title }}</div>
                            </div>
                            <div class="grid grid-cols-7"
                                v-if="citizens.govt_employee_status == 1 && newGeodata.barangay.title != null">
                                <div class="md:px-4 py-2 col-span-3 font-semibold">Barangay</div>
                                <div class="md:px-4 py-2 font-semibold">:</div>
                                <div class="md:px-4 py-2 col-span-3">{{ newGeodata.barangay.title }}</div>
                            </div>
                            <div class="grid grid-cols-7" v-if="citizens.govt_employee_status == 1">
                                <div class="md:px-4 py-2 col-span-3 font-semibold">Designation</div>
                                <div class="md:px-4 py-2 font-semibold">:</div>
                                <div class="md:px-4 py-2 col-span-3">{{ newGeodata.designation.title }}</div>
                            </div>
                            <div class="grid grid-cols-7"
                                v-if="citizens.govt_employee_status == 1 && newGeodata.joining_date != null">
                                <div class="md:px-4 py-2 col-span-3 font-semibold">Joining Date</div>
                                <div class="md:px-4 py-2 font-semibold">:</div>
                                <div class="md:px-4 py-2 col-span-3">{{ newGeodata.joining_date }}</div>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="flex items-center space-x-2 font-semibold text-gray-900 leading-8 mt-4"
                    v-if="citizens.loginfo.username != null">
                    <span clas="text-green-500">
                        <svg class="h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                        </svg>
                    </span>
                    <span class="tracking-wide">Account Information</span>

                </div>
                <div class="text-gray-700">
                    <div class=" text-sm">

                        <div class="grid grid-cols-7" v-if="citizens.loginfo.username != null">
                            <div class="md:px-4 py-2 col-span-3 font-semibold">Created by</div>
                            <div class="md:px-4 py-2 font-semibold">:</div>
                            <div class="md:px-4 py-2 col-span-3">{{ citizens.createby ? citizens.createby.name : null }}
                            </div>
                        </div>
                        <div class="grid grid-cols-7" v-if="citizens.loginfo.username != null">
                            <div class="md:px-4 py-2 col-span-3 font-semibold">Office</div>
                            <div class="md:px-4 py-2 font-semibold">:</div>
                            <div class="md:px-4 py-2 col-span-3">
                                <template v-if="citizens.createby">
                                    <template v-if="citizens.createby.employee_geo">
                                        <template v-if="citizens.createby.employee_geo.office">
                                            {{ citizens.createby.employee_geo.office.office_name }}
                                        </template>
                                    </template>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="text-gray-700 py-2 text-right">
                    <div>
                        <button type="button"
                            class="inline-block px-6 py-2.5 bg-green-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-600 hover:shadow-lg focus:bg-green-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-lg transition duration-150 ease-in-out"
                            @click="update(citizens.id, 1)">Approve</button>
                    </div>
                </div>
            </div>
            <div class="">
                <div class="bg-white md:p-3 p-1 shadow-sm rounded-sm">
                    <div class="flex items-center space-x-2 font-semibold text-gray-900 leading-8">
                        <span class="tracking-wide">Attached ID</span>
                    </div>
                    <div class="text-gray-700">
                        <div class="text-sm mt-2">
                            <div class="flex flex-wrap justify-center cursor-pointer"
                                v-if="citizens.govt_issue_id_image != null" @click="showSingle">
                                <img :src="att_url + '/storage/' + citizens.govt_issue_id_image"
                                    class="md:max-w-sm lg:max-w-sm h-auto transition-shadow ease-in-out duration-300 shadow-none hover:shadow-xl"
                                    alt="" />
                            </div>
                            <div class="flex flex-wrap justify-center" v-else>
                                <div>
                                    <i class="fa-solid fa-image text-[285px] text-[#8f8f8f]"></i>
                                    <p style="text-center">No Govt. Issued ID Available</p>
                                </div>

                            </div>
                            <vue-easy-lightbox :visible="visibleRef" :imgs="imgsRef" :index="indexRef"
                                @hide="onHide"></vue-easy-lightbox>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { ref, toRefs, inject, onMounted, defineComponent } from 'vue'
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'
import pickBy from 'lodash/pickBy'
import Layout from '@/Shared/Layout'
import throttle from 'lodash/throttle'
import mapValues from 'lodash/mapValues'
import Pagination from '@/Shared/Pagination'
import SearchFilter from '@/Shared/SearchFilter'
import Breadcrumbs from '@/Shared/Breadcrumbs'
import StatusLevel from '@/Shared/StatusLevel'
import Button from '@/Shared/Button'
import TextareaInput from '@/Shared/TextareaInput'

import VueEasyLightbox from 'vue-easy-lightbox'
export default defineComponent({
    components: {
        Head,
        Icon,
        Link,
        Pagination,
        SearchFilter,
        Breadcrumbs,
        StatusLevel,
        Button,
        TextareaInput,
        VueEasyLightbox
    },
    layout: Layout,
    props: {
        citizens: Object,
        geodata: Object,
        newGeodata: Object,
        att_url: String
    },
    setup(props) {
        const { citizens, att_url, geodata } = toRefs(props)
        const type = ref('edit')
        const title = ref(citizens.value.first_name)
        const head = ref('Profile Verification')
        const url = ref('/backend/citizens')
        const swal = inject('$swal')
        const createUrl = ref('')
        const remarks = ref('')
        const visibleRef = ref(false)
        const indexRef = ref(0) // default 0
        const imgsRef = ref([])
        const permanent_reject = ref(Boolean)
        const form = useForm({
            id: null,
            remarks: null,
            status: null,
            permanent_reject: null
        })
        const update = () => {
            swal({
                title: 'Are you sure?',
                text: "You want to proceed with this action",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.isConfirmed) {
                    form.put(`${url.value}/${citizens.value.id}`)
                    swal.fire(
                        'Updated!',
                        'Your infomation has been Updated.',
                        'success'
                    )
                }
            });
        }
        const onShow = () => {
            visibleRef.value = true
        }
        const showSingle = () => {
            imgsRef.value = att_url.value + '/storage/' + citizens.value.govt_issue_id_image
            onShow()
        }
        const onHide = () => (visibleRef.value = false)
        return {
            citizens,
            geodata,
            att_url,
            url,
            type,
            title,
            head,
            createUrl,
            remarks,
            permanent_reject,
            visibleRef,
            indexRef,
            imgsRef,
            showSingle,
            onHide,
            swal,
            update
        }
    },
})
</script>
<style lang="scss" scoped>
    span {
        display: inline-block;

        &>i {
            font-size: 20px;
            color: #ccc;
        }

        &.success>i {
            color: #009a66;
        }

        &.fail>i {
            color: #c32700;
        }
    }

    .flex.items-center.space-x-2.font-semibold.text-gray-900.leading-8 {
        background: #f1f5f9;
        padding: 5px;
    }

    .wordWrap {
        word-wrap: break-word;
    }
</style>
