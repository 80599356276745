<template>
    <TransitionRoot as="template" :show="locationopen">
        <Dialog as="div" class="relative z-[9999]" @click="update_location_popup(false)">
            <div class="fixed inset-0" />
            <div class="fixed inset-0 overflow-hidden">
                <div class="absolute inset-0 overflow-hidden">
                    <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                        <TransitionChild as="template"
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enter-from="translate-x-full" enter-to="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leave-from="translate-x-0" leave-to="translate-x-full">
                            <DialogPanel class="pointer-events-auto w-screen max-w-md">
                                <form class="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                                    <div class="h-0 flex-1 overflow-y-auto">
                                        <div class="bg-indigo-700 px-4 py-6 sm:px-6">
                                            <div class="flex items-center justify-between">
                                                <DialogTitle class="text-base font-semibold leading-6 text-white">
                                                    Location
                                                </DialogTitle>
                                                <div class="ml-3 flex h-7 items-center">
                                                    <button type="button"
                                                        class="relative rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                                        @click="update_location_popup(false)">
                                                        <span class="absolute -inset-2.5" />
                                                        <span class="sr-only">Close panel</span>
                                                        <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="mt-1">
                                                <p class="text-sm text-indigo-300">Add location for this post.</p>
                                            </div>
                                        </div>
                                        <div class="flex flex-1 flex-col justify-between">
                                            <div class="divide-y divide-gray-200 px-4 sm:px-6">
                                                <div class="space-y-6 pb-5 pt-6">
                                                    <div
                                                        class="mt-1 rounded-md border border-gray-300 p-3 max-h-[485px] overflow-y-auto">

                                                        <div v-if="message"
                                                            class="flex items-center gap-2 message_block error"><i
                                                                class="fa fa-check"></i> {{ message }}<i
                                                                class="fa fa-times close_btn" @click="remove_msg"></i>
                                                        </div>


                                                        <form @submit.prevent="data_submit">
                                                            <div class="pb-8 pr-6 w-full">
                                                                <label
                                                                    class="block text-sm font-medium text-gray-700 dark:text-gray-400">Choose
                                                                    Region<span class="custom-error">*</span></label>
                                                                <div class="mt-1">

                                                                    <select v-model="form.region_id"
                                                                        class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                                                        @change="load_province(1)" required>
                                                                        <option value=null>Choose One</option>
                                                                        <option v-for="region in region_list"
                                                                            :value="region.id">{{ region.region_name }}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div class="pb-8 pr-6 w-full">
                                                                <label
                                                                    class="block text-sm font-medium text-gray-700 dark:text-gray-400">Choose
                                                                    Province</label>
                                                                <div class="mt-1">

                                                                    <select v-model="form.province_id"
                                                                        class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                                                        @change="load_municipality(1)">
                                                                        <option value=null>Choose One</option>
                                                                        <option v-for="province in province_list"
                                                                            :value="province.id">{{
                                                                                province.provience_name }}</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div class="pb-8 pr-6 w-full">
                                                                <label
                                                                    class="block text-sm font-medium text-gray-700 dark:text-gray-400">Choose
                                                                    Municipality </label>
                                                                <div class="mt-1">
                                                                    <select v-model="form.municipality_id"
                                                                        class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                                                        @change="load_barangay(1)">
                                                                        <option value=null>Choose One</option>
                                                                        <option
                                                                            v-for="municipality in municipality_list"
                                                                            :value="municipality.id">
                                                                            {{ municipality.municipality_name }}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div class="pb-8 pr-6 w-full">
                                                                <label
                                                                    class="block text-sm font-medium text-gray-700 dark:text-gray-400">Choose
                                                                    Barangay </label>
                                                                <div class="mt-1">
                                                                    <select v-model="form.barangay_id"
                                                                        class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm">
                                                                        <option value=null>Choose One</option>
                                                                        <option v-for="barangay in barangay_list"
                                                                            :value="barangay.id">{{
                                                                                barangay.barangay_name }}</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex flex-shrink-0 justify-end px-4 py-4" v-if="edit == false">
                                        <button type="button" @click="saveLocationData()"
                                            class="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Save</button>
                                    </div>
                                </form>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>


import axios from 'axios';
import { ref, toRefs, inject, onMounted, watch } from 'vue'
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'

import TextInput from '@/Shared/TextInput'
import SelectInput from '@/Shared/SelectInput'
import StatusToogleButton from '@/Shared/StatusToogleButton'
import FileInput from '@/Shared/FileInput'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import Button from '@/Shared/Button'
import FloatingActionBtnFix from "@/Shared/Buttons/FloatingActionBtnFix"
import { LinkIcon, PlusIcon, QuestionMarkCircleIcon } from '@heroicons/vue/20/solid'
const props = defineProps(['locations', 'location_list', 'locationopen'])
const location_data = ref(props.locations)
const ropen = ref(props.locationopen)
const backendPrefix = ref(process.env.MIX_ADMIN_PREFIX)
const edit = ref(false)
const swal = inject('$swal')
const preloader = ref(false)

const message = ref('');
const form = useForm({
    region_id: 1,
    region_name: null,
    province_id: null,
    province_name: null,
    municipality_id: null,
    municipality_name: null,
    barangay_id: null,
    barangay_name: null
})
const emit = defineEmits(['UpdateLocationPopup', 'UpdateLocation'])

const save = () => {
    emit('UpdateLocationPopup', props.locationopen)
    emit('UpdateLocation', location_data)
}

const update_location_popup = (status) => {
    emit('UpdateLocationPopup', status)
}

watch(() => props.locationopen, () => {
    emit('UpdateLocationPopup', props.locationopen)
}, { deep: true })

const reset = () => {
    form.region_id = 1;
    form.region_name = null;
    form.province_id = null;
    form.province_name = null;
    form.municipality_id = null;
    form.municipality_name = null;
    form.barangay_id = null;
    form.barangay_name = null;
    region_title(form.region_id);
}
const saveLocationData = async (val) => {
    if (form.region_id > 0) {
        location_data.value.push({
            id: null,
            post_id: null,
            region_id: form.region_id,
            region_name: form.region_name,
            province_id: form.province_id,
            province_name: form.province_name,
            municipality_id: form.municipality_id,
            municipality_name: form.municipality_name,
            barangay_id: form.barangay_id,
            barangay_name: form.barangay_name
        })
        await emit('UpdateLocation', location_data)
        await reset();
        emit('UpdateLocationPopup', false)
    } else {
        message.value = 'Please fill mendatory filed'
    }
}

const region_title = (value) => {
    const index = region_list.value.findIndex((p) => p.id == value);
    form.region_name = region_list.value[index].region_name
};

watch(() => form.region_id, () => {
    region_title(form.region_id);
}, { deep: true }
);


const province_title = (value) => {
    const index = province_list.value.findIndex((p) => p.id == value);
    form.province_name = province_list.value[index].provience_name
};
watch(() => form.province_id, () => {
    province_title(form.province_id);
}, { deep: true }
);

const municipality_title = (value) => {
    const index = municipality_list.value.findIndex((p) => p.id == value);
    form.municipality_name = municipality_list.value[index].municipality_name
};
watch(() => form.municipality_id, () => {
    municipality_title(form.municipality_id);
}, { deep: true }
);

const barangay_title = (value) => {
    const index = barangay_list.value.findIndex((p) => p.id == value);
    form.barangay_name = barangay_list.value[index].barangay_name
};
watch(() => form.barangay_id, () => {
    barangay_title(form.barangay_id);
}, { deep: true }
);

const remove_msg = () => {
    message.value = ''
}

const region_list = ref([])
const load_region = async (req_for) => {
    let data = {
        geo_region_id: req_for == 2 ? form.region_id : form.region_id
    }
    await axios.post(`/${backendPrefix.value}/regions/activeregions`, data)
        .then(function (response) {
            region_list.value = response.data
            form.province_id = null
            form.municipality_id = null
            form.barangay_id = null
            if (form.region_id > 0) {
                region_title(form.region_id);
            }
            if (form.province_id) load_municipality(2)
        }.bind(this));
}
const province_list = ref([])
const load_province = async (req_for) => {
    let data = {
        geo_region_id: req_for == 2 ? form.region_id : form.region_id
    }
    await axios.post(`/${backendPrefix.value}/provinces/activeprovinces`, data)
        .then(function (response) {
            province_list.value = response.data
            form.province_id = null
            form.municipality_id = null
            form.barangay_id = null
            if (form.province_id) load_municipality(2)
        }.bind(this));
}
const municipality_list = ref([])
const load_municipality = async (req_for) => {
    let data = {
        geo_provience_id: req_for == 2 ? form.province_id : form.province_id
    }
    await axios.post(`/${backendPrefix.value}/municipalities/activemunicipalities`, data)
        .then(function (response) {
            form.municipality_id = null
            form.barangay_id = null
            municipality_list.value = response.data
            if (form.municipality_id) load_barangay(2)
        }.bind(this));
}

const barangay_list = ref([])
const load_barangay = async (req_for) => {
    let data = {
        geo_municipality_id: req_for == 2 ? form.municipality_id : form.municipality_id
    }
    await axios.post(`/${backendPrefix.value}/barangays/activebarangays`, data)
        .then(function (response) {
            form.barangay_id = null
            barangay_list.value = response.data
        }.bind(this));
}

onMounted(() => {
    load_region();
    load_province(2);
})
</script>
<style lang="scss" scoped>
.message_block {
    position: fixed;
    z-index: 9999;
    left: -300px;
    bottom: 25px;
    color: #fff;
    height: 40px;
    padding: 0 15px;
    border-radius: 5px;
    transition: all 0.4s;

    &>.close_btn {
        position: absolute;
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        background-color: #941212;
        border: 1px solid #fff;
        border-radius: 50%;
        cursor: pointer;
        right: 5px;
        top: -10px;
        font-size: 8px;
        z-index: 5;
    }

    &.error {
        left: auto;
        right: 10%;
        background-color: #fc4949;
    }
}

textarea,
select {
    width: 100%;
    border: 1px solid #ddd;
    padding: 5px 10px;
    border-radius: 5px;
}
</style>
