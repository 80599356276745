<template>
    <TransitionRoot appear :show="isOpen" as="template" class="z-50">
        <Dialog as="div" @click="update_popup(false)" class="relative z-10">
            <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100"
                leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-black/25" />
            </TransitionChild>
            <div class="fixed inset-0 overflow-y-auto">
                <div class="flex min-h-full items-center justify-center p-1 text-center">
                    <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95"
                        enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100"
                        leave-to="opacity-0 scale-95">
                        <DialogPanel
                            class="w-full h-full transform overflow-hidden bg-white p-1 text-left align-middle shadow-xl transition-all">
                            <div class="p-2 h-full w-full">
                                <div class="flex column flex-col h-full w-full justify-between">
                                    <div>
                                        <v-ace-editor v-model:value="data" lang="html" theme="monokai"
                                            class="h-[calc(85vh)]" />
                                    </div>
                                    <div class="flex gap-3 justify-end items-end mt-1 ">
                                      <i class="fa-duotone fa-ban p-2 text-2xl text-red-600 cursor-pointer" @click="update_popup(false)"></i>
                                      <i class="fa-thin fa-floppy-disk p-2 text-2xl text-green-600 cursor-pointer" @click="update_popup(false)"></i>
                                    </div>
                                </div>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>
<script setup>
import axios from 'axios';
import { ref, toRefs, inject, onMounted, watch } from 'vue'
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import { VAceEditor } from 'vue3-ace-editor';
import 'ace-builds/src-noconflict/mode-html'; // Load the language definition file used below
import 'ace-builds/src-noconflict/theme-monokai'; // Load the theme definition file used below

const content = ref(JSON.stringify({ message: 'Hello Ace' }));
import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
} from '@headlessui/vue'

const props = defineProps(['isOpen', 'data'])
const emit = defineEmits(['UpdatePopup', 'UpdateData'])
const data = ref(props.data)
const update_popup = (status) => {
    emit('UpdatePopup', status)
    emit('UpdateData', data.value)
}
watch(() => props.amenityopen, () => {
    emit('UpdatePopup', props.isOpen)
}, { deep: true })
</script>
