<template>
    <div class="w-full">
        <fieldset fieldset class="border border-solid border-gray-300 mt-6 p-3 w-full lg:w-1/1">
            <legend class="text-sm">Approval Required Field</legend>
            <div class="grid grid-cols-1 items-center">
                <Button :name="'Add'" :icon="'far fa-plus-circle'" :method="addApproval"></Button>
                <template v-for="approval, index in approvals" :key="index">
                    <div class="grid grid-cols-7 gap-y-2 gap-x-1 mb-1">
                        <div class="col-span-6">
                            <div class="grid md:grid-cols-5 items-center">
                                <input v-model="approval.field_name" type="text" class="form-input"
                                    placeholder="Field Name" />
                                <input v-model="approval.display_name" type="text" class="form-input"
                                    placeholder="Display Name" />
                                <select name="" id="" v-model="approval.type" class="form-input">
                                    <option value=null>Select Field Types</option>
                                    <option value="text">Text</option>
                                    <option value="date">Date</option>
                                    <option value="number">Number</option>
                                    <option value="time">Time</option>
                                    <option value="datetime-local">Date Time</option>
                                    <option value="file">File</option>
                                    <option value="select">Select</option>
                                </select>
                                <input v-model="approval.selectOption" type="text" class="form-input"
                                    placeholder="Options" v-if="approval.type == 'select'" />
                                <input v-model="approval.min" type="number" class="form-input" placeholder="Min"
                                    v-if="approval.type == 'number'" />
                                <input v-model="approval.max" type="number" class="form-input" placeholder="Max"
                                    v-if="approval.type == 'number'" />
                                <input v-model="approval.minlength" type="number" class="form-input"
                                    placeholder="Min length" v-if="approval.type == 'text'" />
                                <input v-model="approval.maxlength" type="number" class="form-input"
                                    placeholder="Max length" v-if="approval.type == 'text'" />
                                <input v-model="approval.max_size" type="number" class="form-input"
                                    placeholder="Max Size" v-if="approval.type == 'file'" />
                                <input v-model="approval.accept" type="text" class="form-input"
                                    placeholder=".jpg, .jpeg, .png" v-if="approval.type == 'file'" />
                            </div>
                        </div>

                        <div>
                            <span v-show="index != 0"
                                class="cursor-pointer fa-solid fa-trash h-6 ml-3 mt-3 px-2cursor-pointer w-6"
                                @click="deleteApproval(index)"></span>
                        </div>
                    </div>
                </template>
            </div>
        </fieldset>
    </div>
</template>
<script>
import axios from "axios"
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import { ref, toRefs, onMounted } from 'vue'
import TextInput from '@/Shared/TextInput'
import SelectInput from '@/Shared/SelectInput'
import StatusToogleButton from '@/Shared/StatusToogleButton'
import Button from '@/Shared/Button'
import ContentHeaderSection from "@/Shared/ContentHeaderSection"
import DeleteIcon from '@/Shared/DeleteIcon'
export default {
    components: {
        Head,
        Link,
        SelectInput,
        TextInput,
        StatusToogleButton,
        Button,
        ContentHeaderSection,
        DeleteIcon
    },
    remember: 'form',
    props: {
        approvals: Array,
    },
    setup(props) {
        const { approvals } = toRefs(props)
        const addApproval = () => {
            approvals.value.push({
                field_name: null,
                display_name: null,
                type: null,
                min: null,
                max: null,
                minlength: null,
                maxlength: null,
                max_size: null,
                accept: '.jpg, .jpeg, .png'
            })
        }
        const url = ref('/backend/serviceinfos/project')

        const backendPrefix = ref(process.env.MIX_ADMIN_PREFIX)
        const deleteApproval = (counter, id = null) => {
            // if(id!=null){
            //   let data={
            //     id:id
            //   }
            //   axios.post(`/${backendPrefix.value}/serviceinfos/approvaldelete`, data).then( async (response) => {
            //         //console.log(response)
            //     }).catch(error => {
            //         //console.log(error.response.data.error)
            //     })
            // }
            approvals.value.splice(counter, 1);

        }

        onMounted(() => {
        })
        return {
            approvals,
            addApproval,
            deleteApproval,
            url
        }
    },
}
</script>
<style>
.w-6.h-6.text-green-500.cursor-pointer {
    font-size: 17px;
    margin-top: 6px;
}
</style>
