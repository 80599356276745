<template>
    <div class="office_layer_map_block">
        <ContentHeaderSection :title="'Ministry Office '" :icon="'fa fa-cogs'" />
        <div class="p-5 office_layer_setup_block">
            <div v-for="office_ministry in officeMinistries" class="mb-4">
                <div :class="['checkbox_block',{active:sel_ministry[office_ministry.id]}]" tabindex="0"
                    @mouseover="ministry_sel_item(office_ministry.id,'hover')"
                    @mouseout="ministry_sel_item(office_ministry.id,'out')"
                    @click="ministry_sel_item(office_ministry.id,'add')"
                >
                    <i :class="['fa','fa-times',{remove:sel_ministry[office_ministry.id]}]"></i>
                    <i class="fa fa-check checked"></i>
                </div>
                <div :class="['info_block',{active:sel_ministry[office_ministry.id]}]">{{ office_ministry.ministry_name }}</div>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from "axios"
    import { ref, toRefs } from "vue"
    import ContentHeaderSection from "@/Shared/ContentHeaderSection"
    import FloatingActionBtn from "@/Shared/Buttons/FloatingActionBtn"

    export default {
        name: "OfficeLayerBlock",
        props: {
            officeMinistries: Array,
            ministrymap: Array,
            office_id: Number
        },
        components: {
            ContentHeaderSection,
            FloatingActionBtn
        },
        setup(props, { emit }){
            const backendPrefix = ref(process.env.MIX_ADMIN_PREFIX)
            const { officeMinistries, ministrymap, office_id } = toRefs(props)
            const preloader = ref(false)
            const h_ministry_id = ref('')
            const sel_ministry = ref({})
            const message = ref('')

            if(ministrymap.value && ministrymap.value.length > 0){
                ministrymap.value.forEach(v => {
                    sel_ministry.value[v.id] = true
                })
            }

            const ministry_sel_item = (id, req_type) => {
                if(req_type=='hover') h_ministry_id.value = id
                else if(req_type=='out') h_ministry_id.value = ''
                else {
                    if(sel_ministry.value[id]) delete sel_ministry.value[id]
                    else sel_ministry.value[id] = true

                    emit('getMinistry', sel_ministry.value)
                }
            }


            const remove_msg = () => {
                message.value = ''
            }

            return {
                officeMinistries,
                ministrymap,
                h_ministry_id,
                sel_ministry,
                preloader,
                message,
                ministry_sel_item,
                remove_msg
            }
        }
    }
</script>
<style lang="scss" scoped>
    $checkbox_size: 20;
    .office_layer_map_block{
        display: block;
        position: relative;
        // padding-bottom: 50px;
        .office_layer_setup_block{
            display: block;

            & > div{
                display: flex;
                gap: 10px;
                align-items: center;
                .checkbox_block{
                    display: inline-block;
                    width: #{$checkbox_size}px; height: #{$checkbox_size}px;
                    line-height: #{$checkbox_size}px; text-align: center;
                    font-size: 10px;
                    border: 1px solid #ddd;
                    border-radius: 5px;
                    cursor: pointer;
                    transition: all 0.4s;

                    & > i{
                        display: none;
                        opacity: 0; line-height: #{$checkbox_size}px;
                        transition: all 0.4s;
                    }

                    &:hover{
                        color: #ccc;
                        border-color: #ccc;

                        & > i.checked{
                            display: block;
                            opacity: 1;
                        }
                    }
                    &.active{
                        color: #006699;
                        border-color: #006699;
                        & > i.checked{
                            display: block;
                            opacity: 1;
                        }

                        &:hover{
                            color: #CD0000;
                            border-color: #CD0000;
                            & > i.checked{
                                display: none;
                                opacity: 0
                            }
                            & > i.remove{
                                display: block;
                                opacity: 1;
                            }
                        }
                    }
                }
                .info_block{
                    &.active{
                        font-weight: 600;
                    }
                }
            }
        }
    }
</style>
