<template>
    <div>
        <Breadcrumbs :type="type" :header="title" :url="url" :head="head"></Breadcrumbs>
        <trashed-message v-if="emailtemplate.deleted_at" class="mb-6" @restore="restore"> This Faq has been deleted.
        </trashed-message>
        <div class="max-w-5xl bg-white rounded-md shadow overflow-hidden">
            <form @submit.prevent="update">
                <div class="flex flex-wrap -mb-8 -mr-6 p-8">
                    <text-input v-model="form.name" :error="form.errors.name" class="pb-8 pr-6 w-full lg:w-1/1"
                        label="Name" required="true" placeholder="i.e sign up" />
                    <text-input v-model="form.slug" :error="form.errors.slug" class="pb-8 pr-6 w-full lg:w-1/1"
                        label="Slug" required="true" placeholder="i.e sign-up" />

                    <text-input v-model="form.email_subject" :error="form.errors.email_subject"
                        class="pb-8 pr-6 w-full lg:w-1/1" label="Subject" required="true" placeholder="i.e " />
                    <textarea-input v-model="form.email_body" :error="form.errors.email_body"
                        class="pb-8 pr-6 w-full lg:w-1/1" label="Body" required="true" placeholder="i.e " />
                    <status-toogle-button v-model="form.status" :error="form.errors.status" label="Status"
                        class="pb-8 pr-6 w-full lg:w-1/1"></status-toogle-button>
                </div>
                <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
                    <button v-if="!emailtemplate.deleted_at && permission.edit > 0" class="text-red-600 hover:underline"
                        tabindex="-1" type="button" @click="destroy"><delete-icon></delete-icon></button>
                    <Button v-if="permission.edit > 0" :type="type" :name="head" :url="createUrl"
                        :loading="form.processing"></Button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from '@/Shared/Breadcrumbs'
import Button from '@/Shared/Button'
import DeleteIcon from '@/Shared/DeleteIcon'
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import LoadingButton from '@/Shared/LoadingButton'
import SelectInput from '@/Shared/SelectInput'
import StatusToogleButton from '@/Shared/StatusToogleButton'
import TextareaInput from '@/Shared/TextareaInput'
import TextInput from '@/Shared/TextInput'
import TrashedMessage from '@/Shared/TrashedMessage'
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import { ref, toRefs, inject } from 'vue'
import { Quill, VueEditor } from "vue3-editor"
export default {
    components: {
        Head,
        Icon,
        Link,
        LoadingButton,
        SelectInput,
        TextInput,
        TrashedMessage,
        StatusToogleButton,
        Breadcrumbs,
        DeleteIcon,
        Button,
        TextareaInput,
        Quill,
        VueEditor
    },
    layout: Layout,
    props: {
        emailtemplate: Object,
        permission: Object,
    },
    remember: 'form',
    setup(props) {
        const { emailtemplate, permission } = toRefs(props)
        const type = ref('edit')
        const title = ref(emailtemplate.value.name)
        const head = ref('Eamil Template')
        const url = ref('/backend/email-templates')
        const swal = inject('$swal')
        const createUrl = ref('')
        const elm = ref(this)
        const form = useForm({
            name: emailtemplate.value.name,
            slug: emailtemplate.value.slug,
            email_subject: emailtemplate.value.email_subject,
            email_body: emailtemplate.value.email_body,
            status: emailtemplate.value.status
        })
        const update = () => {
            swal({
                title: 'Are you sure?',
                text: "You want to proceed with this action",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.isConfirmed) {
                    form.put(`${url.value}/${emailtemplate.value.id}`)
                    title.value = form.name
                    Swal.fire(
                        'Updated!',
                        'Your infomation has been Updated.',
                        'success'
                    )
                }
            });
        }

        const destroy = () => {
            swal({
                title: 'Are you sure?',
                text: "Do you really want to delete this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    form.delete(`${url.value}/${emailtemplate.value.id}`)
                    Swal.fire(
                        'Deleted!',
                        'Your infomation has been deleted.',
                        'success'
                    )
                }
            });
        }
        const restore = () => {
            swal({
                title: 'Are you sure?',
                text: "You want to restore this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Resore it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    form.put(`${url.value}/${emailtemplate.value.id}/restore`)
                    Swal.fire(
                        'Restored!',
                        'Your infomation has been Restored.',
                        'success'
                    )
                }
            });
        }
        const statusCheck = () => {
            if (emailtemplate.value.status == 1) {
                form.status = true;
                return true;
            } else {
                form.status = false;
                return false;
            }
        }
        return {
            form,
            emailtemplate,
            type,
            title,
            head,
            url,
            createUrl,
            update,
            destroy,
            restore,
            statusCheck,
            swal,
            permission
        }
    },
    created: function () {
        this.statusCheck();
    }

}
</script>
