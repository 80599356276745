<template>
    <div>
      <Breadcrumbs :type="type" :header="title" :url="url"></Breadcrumbs>
      <div class="max-w-lg bg-white rounded-md shadow overflow-hidden">
        <form @submit.prevent="store">
          <div class="flex flex-wrap -mb-8 -mr-6 p-8">
            <text-input v-model="form.language_name" :error="form.errors.language_name" class="pb-8 pr-6 w-full lg:w-1/1" label="Name of Language"  required="true" placeholder="i.e English"/>
            <text-input v-model="form.code" :error="form.errors.code" class="pb-8 pr-6 w-full lg:w-1/1" label="Code"  required="true" placeholder="i.e EN"/>
            <status-toogle-button v-model="form.status" :error="form.errors.status" label="Language Status" class="pb-8 pr-6 w-full lg:w-1/1"></status-toogle-button>
          </div>
          <Button :type="type" :name="title" :url="createUrl" :loading="form.processing"></Button>
        </form>
      </div>
    </div>
  </template>

  <script>
  import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
  import { ref, toRefs, inject } from 'vue'
  import Layout from '@/Shared/Layout'
  import TextInput from '@/Shared/TextInput'
  import SelectInput from '@/Shared/SelectInput'
  import LoadingButton from '@/Shared/LoadingButton'
  import Breadcrumbs from '@/Shared/Breadcrumbs'
  import StatusToogleButton from '@/Shared/StatusToogleButton'
  import Button from '@/Shared/Button'
  export default {
    components: {
      Head,
      Link,
      LoadingButton,
      SelectInput,
      TextInput,
      Breadcrumbs,
      StatusToogleButton,
      Button
    },
    layout: Layout,
    remember: 'form',
    setup(){
      const form = useForm({
        language_name: null,
        code: null,
        status: true
      })
      const type = ref('create')
      const title = ref('Language')
      const url = ref('/backend/languages')
      const createUrl = ref('')
      const swal = inject('$swal')
      const store = () => {
        swal({
            title: 'Are you sure?',
            text: "You want to proceed with this action",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
          }).then((result) => {
            if (result.isConfirmed) {
              form.post(`${url.value}`, {forceFormData: true,})
              Swal.fire(
                'Added!',
                'Your infomation has been Added.',
                'success'
              )
            }
          });

      }
      return {
        form,
        type,
        title,
        url,
        createUrl,
        store
      }
    }
  }
  </script>
