<template>
    <div>
        <Breadcrumbs :type="type" :header="title" :url="url"></Breadcrumbs>
        <div class="w-full bg-white rounded-md shadow overflow-hidden">
            <ContentHeaderSection :title="'Basic Info'" :icon="'fa fa-cogs'" />
            <form @submit.prevent="store" enctype="multipart/form-data">
                <div class="flex flex-wrap -mb-8 -mr-6 p-8">
                    <select-input v-model="form.service_id" :error="form.errors.service_id"
                        class="pb-8 pr-6 w-full lg:w-1/2" label="Service " required="true">
                        <option value=null>Select Service</option>
                        <option v-for="service in services" :key="service.id" :value='service.id'>{{ service.service_name }}
                        </option>
                    </select-input>
                    <status-toogle-button v-model="form.has_subservice" :error="form.errors.status"
                        label="Has Sub-service &nbsp;&nbsp;&nbsp;&nbsp;"
                        class="pb-8 mt-6 pr-6 w-full lg:w-1/2"></status-toogle-button>
                    <template v-if="form.has_subservice == 1">
                        <text-input v-model="form.service_name" :error="form.errors.service_name"
                            class="pb-8 pr-6 w-full lg:w-1/2" label="Sub-Service Name" required="true"
                            placeholder="i.e New" />
                    </template>
                    <text-input v-model="form.duration" :error="form.errors.duration" class="pb-8 pr-6 w-full lg:w-1/2"
                        label="Service Duration (In day)" type="number" oninput="this.value = Math.abs(this.value)" min="0"
                        max="365" />
                    <select-input v-model="form.pre_req_sid" :error="form.errors.pre_req_sid"
                        class="pb-8 pr-6 w-full lg:w-1/2" label="Prerequisite Service">
                        <option value=null>Select Prerequisite Service</option>
                        <option v-for="serviceinfo in serviceinfo_data" :key="serviceinfo.id" :value='serviceinfo.id'>{{
                            serviceinfo.service_name }}</option>
                    </select-input>
                    <div class="pb-8 pr-6 w-full lg:w-1/2">
                        <label class="form-label">Tags</label>
                        <input-tag :tag_info="form.tag_info" @setUpdateTags="set_update_tags" />
                    </div>
                    <div class="pb-8 pr-6 w-full lg:w-1/2 flex">
                        <text-input v-model="form.apply_within" :error="form.errors.apply_within"
                            class="pr-6 pb-8  w-full lg:w-1/2" label="Within (In Day)" type="number"
                            oninput="this.value = Math.abs(this.value)" min="0" max="365" />
                        <text-input v-model="form.apply_after" :error="form.errors.apply_after"
                            class="pr-6 pb-8  w-full lg:w-1/2" label="After (In Day)" type="number"
                            oninput="this.value = Math.abs(this.value)" min="0" max="365" />
                    </div>
                    <text-input v-model="form.number_of_download" :error="form.errors.number_of_download"
                        class="pr-6 pb-8  w-full lg:w-1/2" label="Max certificate download" type="number"
                        oninput="this.value = Math.abs(this.value)" min="0" max="365" />
                    <textarea-input v-model="form.apply_within_comments" :error="form.errors.apply_within_comments"
                        class="pb-8 pr-6 w-full lg:w-1/2" label="Service Apply Within Comments" type="text" />
                    <div class="pb-8 pr-6 w-full lg:w-1/2">
                        <label class="form-label">Recipient</label>
                        <div class="flex ">
                            <div class="form-check form-check-inline">
                                <input
                                    class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                    type="radio" v-model="form.recipient_id" id="recipient_id1" value="1">
                                <label class="form-check-label inline-block text-gray-800"
                                    for="recipient_id1">Citizen</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input
                                    class="form-check-input  appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                    type="radio" v-model="form.recipient_id" id="recipient_id2" value="2">
                                <label class="form-check-label inline-block text-gray-800"
                                    for="recipient_id2">Government</label>
                            </div>
                        </div>
                    </div>


                    <div class="pb-8 pr-6 w-full lg:w-1/2" v-if="form.recipient_id == 2">
                        <label class="form-label">Designation Type</label>
                        <div class="flex ">
                            <div class="form-check form-check-inline">
                                <input
                                    class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                    type="radio" v-model="form.designation_type" id="designation_id1" value="1">
                                <label class="form-check-label inline-block text-gray-800" for="designation_id1">All</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input
                                    class="form-check-input  appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                    type="radio" v-model="form.designation_type" id="designation_id2" value="2">
                                <label class="form-check-label inline-block text-gray-800"
                                    for="designation_id2">Specific</label>
                            </div>
                        </div>
                    </div>

                    <fieldset v-if="form.designation_type == 2 && form.recipient_id == 2"
                        class="border border-solid border-gray-300 mt-6 p-3 w-full lg:w-1/1">
                        <legend class="text-sm">Designations</legend>
                        <div class="flex justify-center">
                            <div class="text-justify">
                                <div class="form-check form-check-inline" v-for="designation in designations"
                                    :key="designations.id">
                                    <input
                                        class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                        type="checkbox" :id="'designation-' + designation.id" v-model="form.designations"
                                        :value='designation.id'>
                                    <label class="form-check-label inline-block text-gray-800"
                                        :for="'designation-' + designation.id">
                                        {{ designation.designation_name }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    <template v-if="form.recipient_id == 2">
                        <status-toogle-button v-model="form.active_employee_check"
                            :error="form.errors.active_employee_check" label="Check Elected User &nbsp;&nbsp;&nbsp;&nbsp;"
                            class="pb-8 mt-6 pr-6 w-full lg:w-1/2"></status-toogle-button>

                            <status-toogle-button v-model="form.employee_data"
                            :error="form.errors.employee_data" label="Take Employee Data &nbsp;&nbsp;&nbsp;&nbsp;"
                            class="pb-8 mt-6 pr-6 w-full lg:w-1/2"></status-toogle-button>
                    </template>


                    <more-content :contents="form.contents" />
                    <status-toogle-button v-model="form.has_attachment" :error="form.errors.has_attachment"
                        label="Has Attachment" class="pb-8 mt-5 pr-6 w-full lg:w-1/1"></status-toogle-button>
                    <required-attachment :attachments="form.attachments" v-if="showattachment == true" />

                    <status-toogle-button v-model="form.has_payment" :error="form.errors.has_payment"
                        label="Has Payment &nbsp;&nbsp;&nbsp;&nbsp;"
                        class="pb-8 pr-6 mt-6 w-full lg:w-1/1"></status-toogle-button>

                    <payment-config :payment_gateways="payment_gateways" :payments="form.payments"
                        v-if="showpayment == true" />

                    <status-toogle-button v-model="form.has_project" :error="form.errors.has_project"
                        label="Has Project &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                        class="pb-8 pr-6 mt-6 w-full lg:w-1/1"></status-toogle-button>
                    <add-project :projects="form.projects" v-if="showproject == true" />


                    <fieldset class="border border-solid border-gray-300 mt-6 p-3 w-full lg:w-1/1">
                        <legend class="text-sm">Decision Config</legend>
                        <div class="flex justify-center">
                            <div class="text-justify">
                                <div class="form-check form-check-inline" v-for="decision_info in decision_infos"
                                    :key="decision_info.id">
                                    <input
                                        class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                        type="checkbox" :id="'decisioninfo-' + decision_info.id" v-model="form.decisions"
                                        :value='decision_info.id'>
                                    <label class="form-check-label inline-block text-gray-800"
                                        :for="'decisioninfo-' + decision_info.id">
                                        {{ decision_info.d_title }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </fieldset>

                    <FinalApproval :approvals="form.approvals" />

                    <DecisionReason :decisionReasons="form.decisionReasons" :decision_infos="decision_infos" />

                    <status-toogle-button v-model="form.status" :error="form.errors.status"
                        label="Service Status &nbsp;&nbsp;&nbsp;&nbsp;"
                        class="pb-8 mt-6 pr-6 w-full lg:w-1/2"></status-toogle-button>
                </div>
                <Button :type="type" :name="title" :url="createUrl" :loading="form.processing"></Button>

            </form>
        </div>
    </div>
</template>

<script>
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import { ref, toRefs, onMounted, watchEffect } from 'vue'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import TextareaInput from '@/Shared/TextareaInput'
import SelectInput from '@/Shared/SelectInput'
import LoadingButton from '@/Shared/LoadingButton'
import StatusToogleButton from '@/Shared/StatusToogleButton'
import Breadcrumbs from '@/Shared/Breadcrumbs'
import ContentHeaderSection from "@/Shared/ContentHeaderSection"
import InputTag from '@/Shared/InputTag'
import RequiredAttachment from './components/RequiredAttachment'
import PaymentConfig from './components/PaymentConfig'
import AddProject from './components/AddProject'
import MoreContent from './components/MoreContent'
import FinalApproval from './components/FinalApproval'
import Button from '@/Shared/Button'
import FileInput from '@/Shared/FileInput'
import DecisionReason from './components/DecisionReason'
export default {
    components: {
        Head,
        Link,
        LoadingButton,
        SelectInput,
        TextInput,
        Breadcrumbs,
        StatusToogleButton,
        Button,
        ContentHeaderSection,
        InputTag,
        RequiredAttachment,
        PaymentConfig,
        AddProject,
        MoreContent,
        Button,
        FinalApproval,
        FileInput,
        TextareaInput,
        DecisionReason
    },
    layout: Layout,
    remember: 'form',
    props: {
        servicecategories: Array,
        services: Array,
        serviceinfo_data: Array,
        payment_gateways: Array,
        decision_infos: Array,
        approvals: Array,
        designations: Array
    },

    setup(props) {
        const { servicecategories, services, serviceinfo_data, designations } = toRefs(props)
        const form = useForm({
            service_name: 'Default',
            recipient_id: 1,
            service_id: null,
            status: true,
            scat_id: null,
            duration: null,
            pre_req_sid: null,
            apply_within: null,
            apply_after: null,
            apply_within_comments: null,
            app_type: 1,
            has_attachment: 0,
            has_payment: 0,
            has_project: 0,
            status: true,
            logo: null,
            has_subservice: 0,
            slug: null,
            tag_info: [],
            designation_type: 1,
            designations: [],
            attachments: [{
                title: '',
                max_file_size: null,
                file_type: [],
                marital_status: [],
                mandatory: true,
                for_certificate: false,
            }],
            payments: [{
                payment_method: [],
                payment_type: 1,
                gateway_id: null,
                instruction: null,
                fixed_amount: null,
                pre_amount: 0,
                amount: 0,
            }],
            projects: [{
                title: null,
                project_location: null,
                project_year: null,
                project_amount: null,
                online: true
            }],
            contents: [{
                title: null,
                type: null,
                description: null,
            }],
            approvals: [{
                field_name: null,
                display_name: null,
                type: null,
                min: null,
                max: null,
                minlength: null,
                maxlength: null,
                max_size: null,
                accept: '.jpg, .jpeg, .png'
            }],
            decisionReasons: [{
                decision_type: null,
                decision_text: null
            }],
            decisions: [],
            active_employee_check: 0,
            number_of_download: 0,
            employee_data:0
        })
        const type = ref('create')
        const title = ref('Service Info')
        const url = ref('/backend/serviceinfos')
        const createUrl = ref('')
        const showattachment = ref(false)
        const showpayment = ref(false)
        const showproject = ref(false)
        const set_update_tags = (obj) => {
            form.tag_info = obj
        }

        watchEffect(() => {
            if (form.has_attachment == 1) {
                showattachment.value = true
            } else {
                showattachment.value = false
            }
            if (form.has_payment == 1) {
                showpayment.value = true
            } else {
                showpayment.value = false
            }
            if (form.has_project == 1) {
                showproject.value = true
            } else {
                showproject.value = false
            }
        });

        const store = () => {
            form.post(`${url.value}`, { forceFormData: true, })
        }
        return {
            servicecategories,
            serviceinfo_data,
            services,
            designations,
            form,
            type,
            title,
            url,
            createUrl,
            store,
            showattachment,
            showpayment,
            showproject,
            set_update_tags
        }
    },
}
</script>
<style></style>
