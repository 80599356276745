<template>
    <div class="w-full lg:w-1/1">
        <fieldset class="border border-solid border-gray-300 ">
            <legend class="text-sm">Attachment Info</legend>
            <div class="flex flex-wrap -mb-8 -mr-6 p-8 previous"

            v-for="(attachment, counter) in attachments"
            v-bind:key="counter">
                <span class="deleteAttachment" @click="deleteAttachment(counter,attachment.id)"><delete-icon/></span>
                <text-input v-model="attachment.title"  class="pb-8 pr-6 w-full lg:w-1/2" label="Attachment Title"  placeholder="i.e Unified Multi-Purpose ID"/>
                <select-input v-model="attachment.max_file_size" class="pb-8 pr-6 w-full lg:w-1/2" label="Max File Size"  >
                  <option value=null >Select File Size</option>
                  <option value='256' >256k</option>
                  <option value='512' >512k</option>
                  <option value='1024' >1M</option>
                  <option value='2048' >2M</option>
                </select-input>
                <div class="pb-8 pr-6 w-full lg:w-1/2"><label class="form-label" for="select-input-2d9f2c4c-196d-423c-ac78-67775ab609e8">File Type</label>
                  <div class="text-justify">
                      <div class="form-check form-check-inline"  v-for="(file_type,index) in file_types" :key="file_type.id"   >
                        <input class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" v-model="attachment.file_type" :value='file_type.id' :id="'inlinecheck-'+file_type.id+'-'+counter" />

                        <label class="form-check-label inline-block text-gray-800" :for="'inlinecheck-'+file_type.id+'-'+counter">
                          {{ file_type.name }}
                        </label>
                      </div>
                  </div>
                </div>
                <div class="pb-8 pr-6 w-full lg:w-1/2"><label class="form-label" for="select-input-2d9f2c4c-196d-423c-ac78-67775ab609e8">Marital Status </label>
                  <div class="text-justify">
                      <div class="form-check form-check-inline"  v-for="(marital_status,index) in marital_statuses" :key="marital_status.id"   >
                        <input class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" v-model="attachment.marital_status" :value='marital_status.id' :id="'inlinecheck1-'+marital_status.id+'-'+counter" />
                        <label class="form-check-label inline-block text-gray-800" :for="'inlinecheck1-'+marital_status.id+'-'+counter">
                          {{ marital_status.name }}
                        </label>
                      </div>
                  </div>
                </div>
                <status-toogle-button v-model="attachment.mandatory"  label="Is Mandatory" class="pb-8 mt-6 pr-6 w-full lg:w-1/2" ></status-toogle-button>
                <status-toogle-button v-model="attachment.for_certificate"  label="For Certificate" class="pb-8 mt-6 pr-6 w-full lg:w-1/2" ></status-toogle-button>
            </div>
            <div class="flex items-center justify-end px-8 py-4 bg-gray-50 border-t border-gray-100">
              <button class="btn-indigo" @click="addAttachment" type="button">
              <i class="fa fa-plus-circle"></i>
                <span>Add</span>
                <span class="hidden md:inline">&nbsp;{{ name }}</span>More Attachment</button>
            </div>
        </fieldset>
    </div>
</template>
<script>
    import axios from "axios"
    import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
    import { ref, toRefs, onMounted } from 'vue'
    import TextInput from '@/Shared/TextInput'
    import SelectInput from '@/Shared/SelectInput'
    import StatusToogleButton from '@/Shared/StatusToogleButton'
    import Button from '@/Shared/Button'
    import ContentHeaderSection from "@/Shared/ContentHeaderSection"
    import DeleteIcon from '@/Shared/DeleteIcon'
    export default {
    components: {
      Head,
      Link,
      SelectInput,
      TextInput,
      StatusToogleButton,
      Button,
      ContentHeaderSection,
      DeleteIcon
    },
    remember: 'form',
    props: {
      attachments: Array,
    },
    setup(props){
      const { attachments } = toRefs(props)
      const backendPrefix = ref(process.env.MIX_ADMIN_PREFIX)
      const addAttachment =()=>{
        attachments.value.push({
          title: '',
          max_file_size:null,
          file_type:[],
          marital_status:[],
          mandatory:true,
          for_certificate:false,
        })
      }
      const file_types=ref([
        {id: '1',name:'jpeg'},
        {id: '2',name:'pdf'},
        {id: '3',name:'jpg'},
        {id: '4',name:'png'}
      ])
      const marital_statuses=ref([
        {id: '1',name:'Married'},
        {id: '2',name:'Unmarried'},
        {id: '3',name:'Divorced'},
        {id: '4',name:'Widowed/Widower'}
      ])

      const mandatoryCheck = (e) => {
        if(e.target.value>0){
          e.target.value=true
        }else{
          e.target.value=false;
        }
      }

      const deleteAttachment= (counter,id=null)=>{
        if(id!=null){
          let data={
            id:id
          }
          axios.post(`/${backendPrefix.value}/serviceinfos/attachmentdelete`, data).then( async (response) => {
              //  //console.log(response)
            }).catch(error => {
              //  //console.log(error.response.data.error)
            })
        }
        attachments.value.splice(counter,1);

      }
      return {
        attachments,
        addAttachment,
        deleteAttachment,
        mandatoryCheck,
        file_types,
        marital_statuses
      }
    },
    created: function(){
     // this.mandatoryCheck();
    }
  }
</script>
<style>
.deleteAttachment {
  position: absolute;
  right: 53px;
  top: 22px;
}
.previous {
  position: relative;
}
.deleteAttachment {
    position: absolute;
    right: 53px;
    top: 22px;
}
legend.text-sm {
  font-size: 14px;
  font-weight: 500;
  padding-left: 5px;
  padding-right: 5px;
}
</style>
