<template>
    <div class="properties_block">
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Type</label>
            <div>Selectbox Input</div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Name</label>
            <div><input class="px-2" type="text" v-model="property_form_data.name" placeholder="Enter Name" @keyup="add_properties" @change="add_properties" /></div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Label</label>
            <div><input type="text" class="px-2" v-model="property_form_data.label" placeholder="Enter Label" @keyup="add_properties" @change="add_properties" /></div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Required</label>
            <div class="form-check form-switch">
                <input class="form-check-input appearance-none float-left align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm switch_btn" type="checkbox" role="switch" id="flexSwitchCheckChecked" v-model="property_form_data.required" @change="add_properties" />
            </div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>For Certificate <sup v-if="certificate_data[property_form_data.name]">*</sup></label>
            <div class="form-check form-switch">
                <input class="form-check-input appearance-none float-left align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm switch_btn" type="checkbox" role="switch" id="flexSwitchCheckChecked" v-model="property_form_data.for_certificate" @change="add_properties" />
            </div>
        </div>
        <div v-if="qr_data" class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>For QR Data <sup v-if="qr_data[property_form_data.name]">*</sup></label>
            <div class="form-check form-switch">
                <input class="form-check-input appearance-none float-left align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm switch_btn" type="checkbox" role="switch" id="flexSwitchCheckChecked" v-model="property_form_data.for_qr" @change="add_properties" />
            </div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>For Readonly</label>
            <div class="form-check form-switch">
                <input class="form-check-input appearance-none float-left align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm switch_btn" type="checkbox" role="switch" id="flexSwitchCheckChecked" v-model="property_form_data.for_readonly" @change="add_properties" />
            </div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label class="leading-[14px]">Dynamic Options</label>
            <div class="form-check form-switch">
                <input class="form-check-input appearance-none float-left align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm switch_btn" type="checkbox" role="switch" id="flexSwitchCheckChecked" v-model="property_form_data.dynamic_options" @input="add_properties" />
            </div>
        </div>
        <template v-if="property_form_data.dynamic_options">
            <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
                <label class="leading-[14px]">Dynamic Option Type</label>
                <div>
                    <select v-model="property_form_data.dynamic_option_type" @input="add_properties">
                        <option value="">Select One</option>
                        <option value="1">Year</option>
                        <option value="2">Month</option>
                    </select>
                </div>
            </div>
            <div v-if="property_form_data.dynamic_option_type=='1'" class="block border border-collapse p-2">
                <label>Year Range Setup</label>
                <div class="flex flex-row gap-2 mt-2">
                    <div class="h-full">
                        <input v-model="property_form_data.dynamic_option_config.min_year" type="number" min="1900" placeholder="i.e. 1971" class="px-2" @input="add_properties" />
                    </div>
                    <div class="h-full"></div>                    
                    <div class="h-full">
                        <input v-if="!property_form_data.dynamic_option_config.cur_year" v-model="property_form_data.dynamic_option_config.max_year" type="number" min="1900" :placeholder="`i.e. ${cur_year}`" class="px-2" @input="add_properties" />
                        <div class="flex flex-row gap-1 mt-2 cursor-pointer text-[10px] whitespace-nowrap" @click="property_form_data.dynamic_option_config.cur_year=!property_form_data.dynamic_option_config.cur_year; add_properties()">
                            <i v-if="property_form_data.dynamic_option_config.cur_year" class="far fa-square-check text-green-600"></i>
                            <i v-else class="far fa-square"></i>
                            <span>Current Year</span>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="block border border-collapse p-2">
                <label>Options</label>
                <template v-if="property_form_data.options.length>0">
                    <draggable :list="property_form_data.options" handle=".drag_cross">
                        <div v-for="(option,index) in property_form_data.options" class="grid grid-cols-7 items-center gap-2 mt-3">
                            <div class="text-center cursor-move"><i class="fa fa-fa fa-up-down-left-right drag_cross"></i></div>
                            <div class="col-span-3"><input type="text" class="px-2" v-model="property_form_data.options[index].label" placeholder="Enter Label" @keyup="add_properties" @change="add_properties" /></div>
                            <div class="col-span-2"><input type="text" class="px-2" v-model="property_form_data.options[index].value" placeholder="Enter Value" @keyup="add_properties" @change="add_properties" /></div>
                            <div class="text-center text-red-900" @click="remove_option(index)"><i class="fa fa-trash-alt"></i></div>
                        </div>
                    </draggable>
                </template>
                <div class="flex items-center justify-center gap-1 h-8 rounded-md bg-gray-200 transition-all hover:bg-gray-300 hover:transition-shadow border cursor-pointer add_opt_btn mt-3" @click="add_new_option">
                    <i class="fa fa-plus-circle"></i>
                    <span>Add New Option</span>
                </div>
            </div>
            <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
                <label>Data Preview</label>
                <div>
                    <select v-model="property_form_data.data_preview" @change="add_properties">
                        <option value="1">Label</option>
                        <option value="2">Value</option>
                    </select>
                </div>
            </div>
        </template>
        <pre>{{ data }}</pre>
    </div>
</template>
<script setup>
    import { ref, watch } from 'vue'
    import { VueDraggableNext as draggable } from 'vue-draggable-next'
    const props = defineProps({
        data: Object,
        certificate_data: Object,
        qr_data: Object
    })
    const emit = defineEmits(['addProperties','modifyCertificateData','removeCertificateData'])
    const name_key_press = ref(false)
    const cur_year = ref(new Date().getFullYear())
    const property_form_data = ref({
        name: props.data && props.data.name?props.data.name:'',
        type: props.data && props.data.type?props.data.type:'selectbox',
        value: props.data && props.data.value?props.data.value:'',
        label: props.data && props.data.label?props.data.label:'',
        required: props.data && props.data.required?props.data.required:false,
        for_certificate: props.data && props.data.for_certificate?props.data.for_certificate:false,
        for_qr: props.data && props.data.for_qr?props.data.for_qr:false,
        for_readonly: props.data && props.data.for_readonly?props.data.for_readonly:false,
        dynamic_options: props.data && props.data.dynamic_options?props.data.dynamic_options:false,
        dynamic_option_type: props.data && props.data.dynamic_option_type?props.data.dynamic_option_type:'',
        options: props.data && props.data.options?props.data.options:[],
        dynamic_option_config: props.data && props.data.dynamic_option_config?props.data.dynamic_option_config:{},
        data_preview: props.data && props.data.data_preview?props.data.data_preview:1
    })

    watch(() => props.data, (updateData, oldValue) => {
        // //console.log(updateData, oldValue)
        name_key_press.value = false
        property_form_data.value = {
            name: updateData && updateData.name?updateData.name:'',
            type: updateData && updateData.type?updateData.type:'selectbox',
            value: updateData && updateData.value?updateData.value:'',
            label: updateData && updateData.label?updateData.label:'',
            required: updateData && updateData.required?updateData.required:false,
            for_certificate: updateData && updateData.for_certificate?updateData.for_certificate:false,
            for_qr: updateData && updateData.for_qr?updateData.for_qr:false,
            for_readonly: updateData && updateData.for_readonly?updateData.for_readonly:false,
            dynamic_options: updateData && updateData.dynamic_options?updateData.dynamic_options:false,
            dynamic_option_type: updateData && updateData.dynamic_option_type?updateData.dynamic_option_type:'',
            options: updateData && updateData.options?updateData.options:[],
            dynamic_option_config: updateData && updateData.dynamic_option_config?updateData.data_config:{},
            data_preview: updateData && updateData.data_preview?updateData.data_preview:1
        }
    });

    watch(() => property_form_data.value.name, (updateName, oldName) => {
        // //console.log(updateName, oldName)
        if(updateName!==oldName && name_key_press.value) {
            let col_name_old = oldName.replace(/\s/g,'_').toLowerCase()
            emit('removeCertificateData', col_name_old)
            
            let col_name_new = updateName.replace(/\s/g,'_').toLowerCase()
            emit('modifyCertificateData', col_name_new)
        }
    })

    watch(() => property_form_data.value.for_certificate, (updateData, oldData) => {
        // //console.log(updateData, oldData)
        /**
         * Certificate data push
         */
        let col_name = property_form_data.value.name.replace(/\s/g,'_').toLowerCase()
        if(updateData) emit('modifyCertificateData', col_name)
        else emit('removeCertificateData', col_name)        
    })

    watch(() => property_form_data.value.for_qr, (updateData, oldData) => {
        //console.log(updateData, oldData)
        /**
         * QR data push
         */
        let col_name = property_form_data.value.name.replace(/\s/g,'_').toLowerCase()
        if(updateData) emit('modifyQrData', col_name)
        else emit('removeQrData', col_name)        
    })

    const remove_option = (index) => {
        property_form_data.value.options.splice(index, 1)
    }
    
    const add_new_option = () => {
        let next_number = property_form_data.value.options.length + 1
        let obj = {
            label: 'label-' + next_number,
            value: 'value-' + next_number,
            selected: false
        }

        property_form_data.value.options.push(obj)
    }

    const add_properties = () => {
        name_key_press.value = true
        emit('addProperties', property_form_data.value)
    }
</script>
<style lang="scss" scoped>
    .properties_block{
        font-size: 12px;    
        input,textarea,select{
            width: 100%;
            height: 26px;
            border: 1px solid #ddd;
            border-radius: 5px;

            &.switch_btn{
                width: 50px;
                height: 26px;
                border-radius: 25px;
                margin-left: -30px;
            }
        }
        textarea{
            height: 48px;
        }
    }
</style>