<template>
    <div>
        <sidebar-menu :menu="menudata" @update:collapsed="onToggleCollapse" @item-click="onItemClick"
            :showOneChild="true" @toggle-collapse="onToggleCollapse">
            <template v-slot:header>
                <Link class="logo_link" href="/backend">
                <logo class="fill-white" width="28" height="28" />
                </Link>
            </template>
            <template v-slot:toggle-icon><i class="fas fa-arrows-alt-h"></i></template>
        </sidebar-menu>
    </div>
</template>

<script>
import axios from 'axios';
import Icon from '@/Shared/Icon'
import Logo from '@/Shared/Logo'
import { Link } from '@inertiajs/inertia-vue3'
import { SidebarMenu } from 'vue-sidebar-menu'
export default {
    components: {
        Icon,
        Link,
        SidebarMenu,
        Logo,
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    data() {
        return {
            window: {
                width: 0,
                height: 0
            },
            menudata: [],
        }
    },
    created() {
        this.loadmenu();
    },
    methods: {
        isUrl(...urls) {
            let currentUrl = this.$page.url.substr(1)
            if (urls[0] === '') {
                return currentUrl === ''
            }
            return urls.filter((url) => currentUrl.startsWith(url)).length
        },
        onToggleCollapse() {
            var contentArea = document.getElementsByClassName('content-page')[0]
            var topbarArea = document.getElementsByClassName('topbar')[0]
            var sidebarmenu = document.getElementsByClassName('v-sidebar-menu')[0]

            contentArea.classList.toggle("enlarged");
            topbarArea.classList.toggle("enlarged");
            sidebarmenu.classList.toggle("enlarged");
        },
        handleResize() {
            if (this.window.width < 800) {
                // this.onToggleCollapse();
            }
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },

        loadmenu() {
            let data = {}
            axios.post('/backend/menu-managements/treemenu', data)
                .then(function (response) {
                    //  console.log(response.data);

                    this.menudata = response.data;
                }.bind(this));
        }

    },
}
</script>
<style>
img.fill-white {
    width: 50px;
    margin: 0px auto;
    padding-bottom: 20px;
}

.vsm--menu {
    max-width: 240px !important;
}

.v-sidebar-menu.vsm_expanded {
    max-width: 240px !important;
}

.logo_link {
    background: #f1f5f9;
    border-right: 1px solid #cbcbcb;
}

.logo_link img {
    height: 48px;
}

.v-sidebar-menu.vsm_expanded {
    z-index: 10;
}
</style>
