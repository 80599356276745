<template>
    <div>
      <Breadcrumbs :type="type" :header="title" :url="url"></Breadcrumbs>
      <div class="bg-amber-300">
        <div class="container m-auto grid grid-cols-3">
          <div class="max-w-2xl col-span-2 bg-white rounded-md shadow p-5">
            <form @submit.prevent="savemenu">
                <nested-draggable :menus="data" :fromData="form" />
                <FloatingActionBtnFix :icon="'fa fa-check'" @dataSubmit="savemenu(data)" />
            </form>
          </div>
          <div class="max-w-2xl bg-white rounded-md shadow ml-4">
            <div class="organogram_map_block"></div>
            <div class="organogram_setup_block">
                <ContentHeaderSection :title="'Dashboard Setup'" :icon="'fa fa-cogs'" />
                <div class="p-2 office_layer_setup_block">
                    <form @submit.prevent="store">
                        <div class="flex flex-wrap -mb-8 -mr-6">
                            <text-input v-model="form.nodeName" :error="form.errors.nodeName" class="pb-8 pr-6 w-full lg:w-1/1" label="Name" required="true" placeholder="i.e Name"/>
                            <text-input v-model="form.slug" :error="form.errors.slug" class="pb-8 pr-6 w-full lg:w-1/1" label="Slug" required="true" placeholder="i.e Slug"/>
                            <text-input v-model="form.icon" :error="form.errors.icon" class="pb-8 pr-6 w-full lg:w-1/1" label="Icon" required="true" placeholder="i.e Icon" disabled/>
                            <div class="pb-8 pr-6 w-full lg:w-1/1">
                                <p>
                                    Select Icon, like <vue3-icon-picker v-model="form.icon" /> this icon
                                </p>
                            </div>

                            <div class="flex space-x-2 justify-center">
                                <div>
                                    <button type="button" class="inline-block px-6 py-2.5 bg-green-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-600 hover:shadow-lg focus:bg-green-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-lg transition duration-150 ease-in-out mr-4"  @click='store' >Submit <i class="fa fa-bookmark" aria-hidden="true"></i></button>

                                    <button type="button" class="inline-block px-6 py-2.5 bg-red-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out" @click='reset'>Reset <i class="ace-icon fa fa-retweet icon-on-right bigger-110"></i></button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>

  <script>
    import axios from 'axios';
    import Breadcrumbs from '@/Shared/Breadcrumbs'
    import Button from '@/Shared/Button'
    import Icon from '@/Shared/Icon'
    import Layout from '@/Shared/Layout'
    import Pagination from '@/Shared/Pagination'
    import SearchFilter from '@/Shared/SearchFilter'
    import StatusLevel from '@/Shared/StatusLevel'
    import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
    import { ref, toRefs, onMounted , inject} from 'vue'
    import ContentHeaderSection from "@/Shared/ContentHeaderSection"
    import SelectInput from '@/Shared/SelectInput'
    import TextInput from '@/Shared/TextInput'
    import nestedDraggable from "./infra/nested";
    import EventBus from '@/services/EventBus.js';
    import StatusToogleButton from '@/Shared/StatusToogleButton'
    import FloatingActionBtnFix from "@/Shared/Buttons/FloatingActionBtnFix"
    import Vue3IconPicker from "./components/Vue3IconPicker.vue"
    export default {
        components: {
            Head,
            Icon,
            Link,
            Pagination,
            SearchFilter,
            Breadcrumbs,
            StatusLevel,
            Button,
            ContentHeaderSection,
            SelectInput,
            TextInput,
            axios,
            nestedDraggable,
            EventBus,
            StatusToogleButton,
            FloatingActionBtnFix,
            Vue3IconPicker
        },
        layout: Layout,
        props: {
        },
        setup(props){
            const form = useForm({
                id:null,
                nodeName:null,
                slug: null,
                pid:null,
                icon: 'fas fa-upload',
                status: false,
                serials:null
            })
            const data = ref([])
            const swal = inject('$swal')
            const type = ref('index')
            const title = ref('Dashboard Management')
            const url = ref('/backend/dashboard-managements')
            const createUrl = ref('/backend/dashboard-managements/create')

            EventBus.on('fromdata', data =>{
                form.nodeName=data.nodeName
                form.id=data.id
                form.slug=data.slug
                form.pid=data.pid
                form.icon=data.icon
                form.status=data.status==1?true:false
                form.serials=data.serials
            })
            EventBus.on('deleteStatus', id =>{
               // //console.log(id);
                if(id>0){
                    load_data();
                }
            })

            const reset = () => {
                form.nodeName=null
                form.id=null
                form.pid=null
                form.slug=null
                form.icon='fas fa-upload'
                form.status=null
                form.serials=null
            }
            const store = () => {
                axios.post(`${url.value}`, form)
                .then(function (response) {
                    swal({
                        position: 'top-end',
                        icon: 'success',
                        title: 'The dashboard item has been submitted successfully.',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    reset()
                    load_data()
                }).catch(error => {
                    swal({
                        position: 'top-end',
                        icon: 'error',
                        title: error,
                        showConfirmButton: false,
                        timer: 1500
                    })
                });
                //form.post(`${url.value}`)
            }
            const savemenu=(adata) =>{
                axios.post(`${url.value}/save-position`, adata)
                .then(function (response) {
                   // //console.log(response);
                    swal({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Dashboard successfully updated.',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    load_data()
                    reset()
                }).catch(error => {
                    swal({
                        position: 'top-end',
                        icon: 'error',
                        title: error,
                        showConfirmButton: false,
                        timer: 1500
                    })
                });
            }
            const load_data = () => {
                axios.post(`${url.value}/dashboard`)
                .then(function (response) {
                    console.log(response);
                    data.value=response.data.data
                }).catch(error => {
                // //console.log(error.response.data.error)
                });
            }
            const updateForm = (data) => {
                    form.nodeName=data.nodeName
                    form.id=data.id
                    form.route_name=data.route_name
                    form.pid=data.pid
                    form.route_location=data.route_location
                    form.icon=data.icon
                    form.status=data.status
                    form.serials=data.serials
                }
            onMounted( () => {
                load_data()
            })
            return {
                form,
                type,
                title,
                url,
                createUrl,
                reset,
                store,
                data,
                load_data,
                swal,
                updateForm,
                savemenu
            }
        },

    }
  </script>
<style lang="scss" scoped>
  $checkbox_size: 20;

  .nested_drag{
    min-height: 50px;
    outline: 1px dashed;
    & > li .nested_drag{
        margin-left: 25px
    }
  }
  .office_layer_map_block{
      display: block;
      position: relative;
      padding-bottom: 50px;
      width: 100%;
      .office_layer_setup_block{
          display: block;

          & > div{
              display: flex;
              gap: 10px;
              align-items: center;
              .checkbox_block{
                  display: inline-block;
                  width: #{$checkbox_size}px; height: #{$checkbox_size}px;
                  line-height: #{$checkbox_size}px; text-align: center;
                  font-size: 10px;
                  border: 1px solid #ddd;
                  border-radius: 5px;
                  cursor: pointer;
                  transition: all 0.4s;

                  & > i{
                      display: none;
                      opacity: 0; line-height: #{$checkbox_size}px;
                      transition: all 0.4s;
                  }

                  &:hover{
                      color: #ccc;
                      border-color: #ccc;

                      & > i.checked{
                          display: block;
                          opacity: 1;
                      }
                  }
                  &.active{
                      color: #006699;
                      border-color: #006699;
                      & > i.checked{
                          display: block;
                          opacity: 1;
                      }

                      &:hover{
                          color: #CD0000;
                          border-color: #CD0000;
                          & > i.checked{
                              display: none;
                              opacity: 0
                          }
                          & > i.remove{
                              display: block;
                              opacity: 1;
                          }
                      }
                  }
              }
              .info_block{
                  &.active{
                      font-weight: 600;
                  }
              }
          }
      }
  }
  i.vue3-icon-picker {
        font-size: 25px;
        padding: 6px 10px;
    }
</style>
