<template>
    <div class="profile_edit_content_block">
        <Breadcrumbs :type="type" :header="title" :url="url"></Breadcrumbs>
        <div class="w-full bg-white rounded-md shadow overflow-hidden edit_form_block">
            <form @submit.prevent="store">
                <div class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 mt-3 p-4">
                    <div class="sm:col-span-3">
                        <div class="mt-1 flex items-center h-[225px]">
                            <div
                                class="mt-1 flex flex-wrap justify-center rounded-md border-2 border-dashed border-gray-300 dark:border-black dark:bg-white dark:text-black px-6 pt-5 pb-6">
                                <div class="space-y-1 text-center">
                                    <PhotoBlock :getPhoto="form.icon_img" @SetPhoto="set_photo" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="sm:col-span-3"></div>
                    <text-input v-model="form.partner_name" :error="form.errors.partner_name" class="sm:col-span-2"  label="Name" required="true" placeholder="i.e. XYZ" />
                    <text-input v-model="form.website_url" :error="form.errors.website_url" class="sm:col-span-2"
                        label="Website URL" required="true" placeholder="i.e " />
                    <text-input v-model="form.fb_url" :error="form.errors.fb_url" class="sm:col-span-2" label="Facebook URL" required="true" placeholder="i.e " />
                    <text-input v-model="form.instra_url" :error="form.errors.instra_url" class="sm:col-span-2"
                        label="Instragram URL" placeholder="i.e. " />
                    <text-input v-model="form.tictok_url" :error="form.errors.tictok_url" class="sm:col-span-2"
                        label="Tiktok URL" placeholder="i.e. " />
                    <text-input v-model="form.youtube" :error="form.errors.youtube" class="sm:col-span-2"
                        label="Youtube" placeholder="i.e. " />
                    <status-toogle-button v-model="form.status" :error="form.errors.status" label="Partner Status" class="sm:col-span-2 "></status-toogle-button>
                </div>
                <Button :type="type" :name="title" :url="createUrl" :loading="form.processing"></Button>
            </form>
        </div>
    </div>
</template>

<script>
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import { ref, toRefs, inject, onMounted, watch } from 'vue'
import axios from 'axios';
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import TextareaInput from '@/Shared/TextareaInput'
import SelectInput from '@/Shared/SelectInput'
import LoadingButton from '@/Shared/LoadingButton'
import Breadcrumbs from '@/Shared/Breadcrumbs'
import StatusToogleButton from '@/Shared/StatusToogleButton'
import Button from '@/Shared/Button'
import PhotoBlock from './Photo';
export default {
    components: {
        Head,
        Link,
        LoadingButton,
        SelectInput,
        TextInput,
        Breadcrumbs,
        StatusToogleButton,
        Button,
        TextareaInput,
        PhotoBlock
    },
    props: {
        permission: Array
    },
    layout: Layout,
    remember: 'form',
    setup(props) {
        const { permission } = toRefs(props)
        const form = useForm({
            partner_name: null,
            website_url: null,
            fb_url: null,
            status: null,
            instra_url: null,
            tictok_url: null,
            youtube: null,
            icon_img: null
        })
        const type = ref('create')
        const title = ref('Partner')
        const url = ref('/backend/partners')
        const createUrl = ref('')
        const swal = inject('$swal')
        const store = () => {
            swal({
                title: 'Are you sure?',
                text: "You want to proceed with this action",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.isConfirmed) {
                    form.post(`${url.value}`, { forceFormData: true, })
                    swal.fire(
                        'Added!',
                        'Your infomation has been Added.',
                        'success'
                    )
                }
            });
        }
        const set_photo = (val) => {
            form.icon_img = val
        }
        return {
            form,
            type,
            title,
            url,
            createUrl,
            store,
            permission,
            set_photo
        }
    }
}
</script>
<style lang="scss" scoped>
$button_height: 36;

.profile_edit_content_block {
    display: block;

    .edit_form_block {
        background-color: #ffffff80;

        input,
        textarea,
        select {
            width: 100%;
            border: 1px solid #ddd;
            padding: 5px 10px;
            border-radius: 5px;
        }

        i {
            display: none;
            width: 30px;
            text-align: center;
            transition: all 0.4s;
            color: #888;
            position: absolute;
            margin-left: 265px !important;
            margin-top: -23px;

            &.active {
                display: block;
            }
        }

        .err_msg {
            border-color: #CD0000 !important
        }
    }
}

.save_btn,
.cancel_btn {
    height: #{$button_height}px;
    background-color: #0f8050;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;

    &.cancel_btn {
        border: 1px solid #CD0000;
        color: #CD0000;
        background-color: #fff;
    }
}


.dark {
    .profile_edit_content_block {

        input,
        textarea,
        select {
            background-color: #222;
        }
    }
}

.password_block,
.confirm_password_block {
    p {
        align-items: baseline;
        color: #666;
        display: flex;
        gap: 5px;
        font-size: 10px !important;

        span {
            color: gold !important;
            font-size: 10px !important;
        }
    }
}
</style>
